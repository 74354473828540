.mapDiv {
  z-index: 1;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.inline {
  display: inline-block;
}
.bold {
  display: inline;
  font-weight: bolder;
}
html {
  overflow: auto !important;
}
body {
  overflow-x: auto;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.esri-ui-corner {
  position: contain !important;
  margin-top: 3.3% !important;
  margin-bottom: 4% !important;
}

.esri-ui-top-right {
  background-color: rgba(black, 0.6);
  border-radius: 40px !important;
}

.esri-widget {
    box-sizing: border-box;
    color: #f4f4f4;
    font-size: 14px;
    font-family: "Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 1.3em;
    background-color: #242424;
}

.esri-layer-list__item {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-radius: 40px !important;
    border-bottom: 1px solid rgba(176,150,255,.2);
    position: relative;
    overflow: hidden;
    list-style: none;
    margin: 3px 0;
    padding: 0;
    transition: background-color 125ms ease-in-out;
    
}

.esri-layer-list__item--has-children>.esri-layer-list__item-container{
    padding-left: 19px !important;
}


.esri-compass{
    display: none !important;
}

.esri-layer-list__child-toggle {
    padding: 0 9px;
    color: #b096ff;
    cursor: pointer;
    display: none !important;
}

.esri-layer-list__child-toggle::-webkit-scrollbar-thumb {
    display: none;
}

.esri-layer-list__child-toggle::-webkit-scrollbar-track{
    display: none;
}

.esri-attribution{
    display: none;
}

.esri-layer-list__item-toggle{
    padding: 0 3px;
    cursor: pointer;
    color: #b096ff;
}
/*
.esri-layer-list__item-container{
    border-left: 3px solid transparent;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 7px 12px 20px;
    transition: border-color 250ms ease-in-out;
}
*/
.esri-search__input {
  background-color: rgba(black, 0.6) !important;
  border-radius: 40px !important;
}

.esri-ui-top-left{
    background-color: transparent !important;
    border-radius: 40px !important;
}
.esri-ui-top-left .esri-component{
    background-color: transparent !important;
    border-radius: 40px !important;
}
.esri-widget--button {
    border-radius: 40px !important;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: #b096ff;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition: background-color 125ms ease-in-out;
}

.esri-widget--panel {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-radius: 40px !important;
}

.esri-identity-modal__dialog {
  background-color: rgba(black, 0.6) !important;
  border-radius: 40px !important;
}

.esri-input {
  background-color: rgba(black, 0.6) !important;
  border-radius: 40px !important;
}

.esri-button {
  background-color: #c8aeff !important;
  border-radius: 40px !important;
}

.esri-button--secondary {
  background-color: rgba(black, 0.6) !important;
  border-radius: 40px !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #878787;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8aeff;
  border-radius: 10px;
}

.esri-layer-list__list {
  top: 200px !important;
  right: -200px !important;
}

.districtChartsPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.districtChartsPopup.popupPadding {
  padding: 10px;
  overflow: hidden;
  perspective: 1000px;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  z-index: 1001;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: 1;
}

.tabsRow {
  display: flex;
  text-align: center;
  align-items: stretch;
}
.bodyBorderFromTab {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.selectedBodyBorderFromTab {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
}

.tabContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.tabContainer.tabA {
  position: relative;
  flex: 1;
}

.tabContainer.tabB {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}
.tabContainer.selectedTab {
  cursor: initial !important;
  border-width: 0px 2px 2px 0px;
  background-color: #c8aeff;
  border-color: #c8aeff;
  border-style: solid;
  border-radius: 20px;
}

.tabContainer.defaultTabBorder {
  border-color: #c8aeff;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-radius: 20px;
}

.tabContainer.defaultTabBorder:hover {
  border-color: #c8aeff;
  background-color: #c8aeff44;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-radius: 20px;
}

.ActiveBtn {
  background-color: #c8aeff8a !important;
}

.healthButton:hover {
  border-color: #c8aeff;
  background-color: #c8aeff44;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-radius: 20px;
}


.inactiveTab {
  cursor: default !important;
  border-color: grey !important;
  color: rgba(128, 128, 128, 0.397) !important;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-radius: 20px;
}

.remainingSpaceAfterTabs {
  overflow: hidden;
  width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.exitButton {
  width: 100%;
  cursor: pointer;
}

.homeButton {
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
}

.infoBody {
    position: center;
    margin: auto;
    margin-top: 4%;
    margin-bottom: 4%;
    background-color: rgba(0, 0, 0, 0.6);
    border-width: 0px 12px 12px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 10px;
    padding-bottom: 0px;
    width: 1300px;
    max-width: 80%;
    border-radius: 40px;
}
.loadingView {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(black, 0.3);
}
.loadingImg {
  align-self: center;
  width: 75px;
}

.tabBody {
  border-top-width: 0px;
  position: relative;
  padding: 10px;
  height: 700px;
  overflow: hidden;
}
.tabBodySpacing {
  max-width: 1100px;
  margin: auto;
}
.source {
  text-align: right;
}
.underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.button2 {
  margin-top: 5px;
  background-color: #4caf4f00; /* Green */
  border: solid;
  vertical-align: top;
  color: white;
  border-color: #c8aeff;
  border-radius: 20px;
  padding-bottom: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: 100%;
  height: 55px;
  overflow: hidden;
}
.button1 {
  margin: 5px 5px 5px 5px;
  background-color: #4caf4f00; /* Green */
  border: solid;
  vertical-align: top;
  color: white;
  border-color: #c8aeff;
  border-radius: 20px;
  padding-bottom: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: 23%;
  height: 55px;
  overflow: hidden;
}
.button2:hover,
.button1:hover {
  background-color: #8000802f; /* Green */
  border: solid;
  border-color: #800080;
  color: white;
}
.button1:focus {
  background-color: #8000802f; /* Green */
  border: solid;
  border-color: red;
  color: white;
}

.commonTabBody.chart1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.commonTabBody.chart2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}
.commonTabBody.list1 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}
.commonTabBody.textInformation {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}
.commonTabBody.secondInfo {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}
.commonTabBody.content::after {
  display: flex;
  flex-direction: column;
  content: "";
  clear: both;
}

.selectOption {
  color: white;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.spacedTD {
  padding-right: 10px;
}
.chart1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.chart2 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}
.chart3 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}
.textInformation {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
}
.textInformation.sun_table {
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid;
  margin-bottom: 10px;
}
.textInformation.sun_table.td {
  border: 1px solid #c8aeff;

  padding: 5px;
}

.first_row {
  text-align: left;
  width: 150px;
}

.educationTabSecondInfoBase {
  display: flex;
  flex-direction: column;
}
.titleRow {
  text-align: center;
  margin-bottom: 10px;
}

.titleRow.titleBorder {
  margin: auto;
  width: 70%;
  padding: 5px;
}

.infoRow {
  flex: 1;
  padding: 10px;
}

.infoRow.distances {
  margin-left: 20px;
}

.border {
  border-style: solid;
  border-width: 2px;
}

.educationTabMainInfoBase {
  display: flex;
  flex-direction: column;
}
.titleRow {
  text-align: center;
}
.titleRow.titleBorder {
  margin: auto;
  width: 70%;
  padding: 5px;
}

.infoRow {
  margin-top: 10px;
  flex: 1;
  padding: 10px;
}
.border {
  border-style: solid;
  border-width: 2px;
  border-color: #c8aeff;
  border-radius: 20px;
}

.app {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: black;
    z-index: 1001;
}
.commonTabBody {
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    border-radius: 20px;
}
.chartRelativeBox {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 20px;
}
.chartRelativeBox .chartHolder {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.border1 {
    border-color: #c8aeff;
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
}
.border2 {
    border-color: #c8aeff;
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
}
.list {
    border-color: #c8aeff;
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
}
.list .listTitle {
    border-bottom-color: #c8aeff;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 20px;
}
.list .listItems {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 20px;
}

.tabContainer .tabA{
    position: relative;
    flex: 1;


}

.tabContainer .tabA .tabB{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.75rem;
    cursor: pointer;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;
    border-color: #C8AEFF;
}

.defaultTabBorder:hover{
    border-color: #C8AEFF;
    background-color: #C8AEFF44;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;

}

.defaultTabBorder{
    border-color: #C8AEFF;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;
    font-size: 0.75rem;
}

.cordField{
    width: 250px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0.6);
    border: solid;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;
    border-color: #C8AEFF;
    color: #C8AEFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dynamicTable{
    width: inherit;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.6);
    border: solid;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;
    border-color: #C8AEFF;
    color: white;
    display: flex;
    padding-top: 20px;
    line-height: 20px;
    justify-content: left;
}

#educationBox1{
    position: relative;
    width: 600px;
    height: 300px;
    right: -600px;
    top: -280px
}

#educationBox2{
    position: relative;
    width: 600px;
    height: 300px;
    top: -580px
}

#educationCoordBox{
    position: relative;
    top: -280px;
}

#educationContainer{
    height: 700px;
    overflow: hidden;
}

#educationBarGraph{
  
    position: relative;
    width: 580px;
    height: 300px;
    top: -280px;
}

#educationLineGraph{
    width: 580px;
    height: 300px;
    right: -600px;
    top: 20px;
    position: relative;
}

#energyLineGraphContainer{
    position: relative;
    width: 580px;
    height: 300px;
}

#energyPieGraphContainer1{
    position: relative;
    width: 290px;
    height: 300px;
    right: -600px;
    top: -300px;
}

#energyPieGraphContainer2{
    position: relative;
    width: 290px;
    height: 300px;
    right: -900px;
    top: -600px;
}

#energySolarInfo{
    position: relative;
    top: -600px;
}

#planningRealEstateGraph{
    position: relative;
    width: 580px;
    height: 600px;
}

#planningPopulationGraph{
    position: relative;
    width: 385px;
    height: 400px;
    right: -720px;
    top: -580px;
}

#planningPopulationTable{
  position: relative;
  width: 600px;
  height: 200px;
  right: -600px;
  top: -580px;
}



#healthInfoBoxContainer{
    width: 580px;
    height: 600px;
}

#healthGraphContainer{
    width: 580px;
    height: 500px;
    right: -600px;
    position: relative;
    top: -600px;
}

#judumasCordContainer{
    position: relative;

}

#judumasGraphContainer{
    position: relative;
    top: -445px;
    right: -740px;
    width: 500px;
    height: 300px;
}

#judumasTableContainer{
    width: 730px;
    height: 400px;
    position: relative;

}

#socialCordContainer{
    position: relative;
}

#socialTableContainer{
    width: 730px;
    height: 400px;
    position: relative;
    
}

#socialGraphContainer{
    position: relative;
    top: -410px;
    right: -740px;
    width: 500px;
    height: 300px;
}


#healthButtonContainer{
    height: 100px;
    width: 580px;
    right: -600px;
    position: relative;
    top: -600px;

    flex-wrap: wrap;
    display: flex;
    flex-direction: row
}

.healthButton{
    width: 130px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    cursor: pointer;
    border: solid;
    border-width: 0px 2px 2px 0px;
    border-radius: 20px;
    border-color: #C8AEFF;
    position: relative;
    flex: 1 1 25%;  
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.healthButton:hover{
    background-color: #c8aeff44
}

* {
	box-sizing: border-box;
	overflow: auto;
    font-family: Avenir, Helvetica, Arial, sans-serif;

}

td {
  height: 22px;
}