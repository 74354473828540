.mapDiv {
  z-index: 1;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.DetailedMapDiv{
  z-index: 1;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

